<template>
  <div>
    <div class="card border-0 bg-light shadow-none">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col">
            <h5 class="fw-bold" v-if="enquiry.retreat_booking_id">
              Attached to Booking #{{ enquiry.retreat_booking_id }}
            </h5>

            <h5 class="fw-bold" v-else>Not Attached to Booking</h5>
          </div>
          <div class="col-auto ms-auto">
            <button
              class="btn btn-sm btn-outline-success me-2"
              @click="openNotes"
            >
              <i class="far fa-notes-medical me-2"></i>Notes
            </button>

            <button
              v-if="$can('manage enquiries')"
              class="btn btn-sm btn-outline-danger me-2"
              @click="deleteEnquiry"
            >
              <i class="far fa-notes-medical me-2"></i>Delete enquiry
            </button>

            <button class="btn btn-sm btn-outline-success" @click="close">
              <i class="far fa-times"></i>
            </button>
          </div>
        </div>

        <div class="row mb-4" v-if="interests">
          <div class="col">
            <h5>
              Interests:
            </h5>
            <ul>
              <li>Reasons:
              <ul>
                <li>Hormone <i class="fa fa-check text-success" v-if="interests.reasons.hormone"></i><i class="fa fa-times text-danger" v-else></i></li>
                <li>Digestive <i class="fa fa-check text-success" v-if="interests.reasons.digestive"></i><i class="fa fa-times text-danger" v-else></i></li>
                <li>Weight <i class="fa fa-check text-success" v-if="interests.reasons.weight"></i><i class="fa fa-times text-danger" v-else></i></li>
                <li>Sleep <i class="fa fa-check text-success" v-if="interests.reasons.sleep"></i><i class="fa fa-times text-danger" v-else></i></li>
                <li>Alcohol <i class="fa fa-check text-success" v-if="interests.reasons.alcohol"></i><i class="fa fa-times text-danger" v-else></i></li>
                <li>Food <i class="fa fa-check text-success" v-if="interests.reasons.food"></i><i class="fa fa-times text-danger" v-else></i></li>
                <li>Stress <i class="fa fa-check text-success" v-if="interests.reasons.stress"></i><i class="fa fa-times text-danger" v-else></i></li>
                <li>Emotional <i class="fa fa-check text-success" v-if="interests.reasons.emotional"></i><i class="fa fa-times text-danger" v-else></i></li>

              </ul>
              </li>

              <li>UK Retreat: <i class="fa fa-check text-success" v-if="interests.uk_retreat"></i><i class="fa fa-times text-danger" v-else></i></li>
              <li>Malta Retreat: <i class="fa fa-check text-success" v-if="interests.malta_retreat"></i><i class="fa fa-times text-danger" v-else></i></li>
            </ul>
          </div>
          <div class="col-auto ms-auto">
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-4">
            <label>Update Enquiry status</label>
            <select
              v-model="enquiry.status"
              @change="updateEnquiry"
              class="form-control text-capitalize"
            >
              <option value="" disabled>Select a status</option>
              <option value="unknown">other/unknown</option>
              <option value="hot">hot</option>
              <option value="warm">warm</option>
              <option value="booking">pending booking</option>
              <option value="unsuccessful">unsuccessful</option>
              <option value="prp">prp</option>
              <option value="booked">booked</option>
              <option value="urgent">urgent</option>
              <option value="uk">marketing uk</option>
              <option value="malta">marketing malta</option>
              <option value="new">new</option>
            </select>
          </div>

          <div class="col-md-4">
            <label for="">Call Back Date</label>
            <utc-flat-pickr v-model="enquiry.call_back_date"></utc-flat-pickr>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="card border-0">
              <div class="card-body">
                <p>{{ enquiry.content }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <notes-modal
      v-show="showNotes"
      :model="'enquiries'"
      :model-id="enquiry.id"
      @close="closeNotes"
    ></notes-modal>
  </div>
</template>

<script>
import UtcFlatPickr from "../../../../globals/UtcFlatPickr";
import NotesModal from "../notes/NotesModal";

export default {
  props: ["enquiry"],
  data() {
    return {
      showNotes: false,
      interests: null,
    };
  },
  computed: {
    callBackDate: {
      get() {
        return moment(this.enquiry.call_back_date).toISOString();
      },
      set(val) {
        this.enquiry.call_back_date = val;
      },
    },
  },
  watch: {
    "enquiry.call_back_date": {
      handler: function (val, old) {
        if (val != old) {
          this.updateEnquiry();
        }
      },
    },
  },
  methods: {
    parseInterests() {
      this.interests = JSON.parse(this.enquiry.interests);
    },
    openNotes() {
      this.showNotes = true;
      var notesModal = new this.$bootstrap.Modal(
        document.getElementById("notesViewModal")
      );
      notesModal.show();
    },
    closeNotes() {
      this.showNotes = false;
    },
    updateEnquiry() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/enquiries/" + this.enquiry.id,
          this.enquiry
        )
        .then(({ data }) => {
          console.log(data);
          this.$EventBus.$emit("alert", { message: data.message });
        });
    },
    deleteEnquiry() {
      var result = confirm(
        "Are you sure you want to delete this enquiry?"
      );
      if (result) {
        this.$axios
          .delete(
            process.env.VUE_APP_API_URL + "/enquiries/" + this.enquiry.id
          )
          .then(({ data }) => {
            this.close();
          });
      }
    },
    close() {
      this.$emit("close");
    },
  },
  filters: {
    formatIsoDate(date) {
      return moment(date).toISOString();
    },
  },
  created() {
    this.parseInterests();
  },
  components: {
    UtcFlatPickr,
    NotesModal,
  },
};
</script>

<style></style>
